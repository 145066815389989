import React from "react";
import _ from "lodash";
import { PayPalButton } from "react-paypal-button-v2";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "./Payment.styles";
import { css } from "aphrodite";
import StripeAddCard from "../StripeAddCard/StripeAddCardContainer";
import { Elements, StripeProvider } from "react-stripe-elements";
import Utils from "../../../library/utils";
import STYLES from "../../../styles/shared";
import { KIFFGO } from "../../../library/kiffgo";
import Check from "@material-ui/icons/CheckCircleOutlineRounded";
import ExcellentReviews from "../../../components/booking/ExcellentReviews/ExcellentReviews";
import GiveBernyACall from "../../../components/booking/GiveBernyACall/GiveBernyACall";
import PrimaryButton from "../../../components/buttons/PrimaryButton/PrimaryButton";
import { PAYMENT_METHOD } from "./PaymentContainer";

const Payment = (props) => {
  // final price
  // const { selectedVanSizeIndex, bookingPricesCustomChoices } = props;
  // Getting selected vehicle estimate price

  const finalDeposit = Utils.getFinalDeposit(
    props.selectedVanSizeIndex,
    props.bookingPricesCustomChoices
  );

  const finalReward = Utils.getFinalReward(
    props.selectedVanSizeIndex,
    props.bookingPricesCustomChoices
  );

  return (
    <div className={css(styles.root)}>
      <div className={css(styles.content)}>
        <div className={css(styles.paymentArea)}>
          <div className={css(styles.cardContainer)}>
            <div className={css(styles.cardContainerContent)}>
              <div className={css(styles.titleArea)}>
                <h1 className={css(styles.paymentMethodTitle, STYLES.noSelect)}>
                  Confirm Booking
                </h1>
                <div style={{ flex: 1 }} />
                <div className={css(styles.acceptedCardsContainer)}>
                  {[1, 2, 10, 14, 16, 22].map((card) => {
                    return (
                      <img
                        key={card}
                        className={css(styles.cardImg)}
                        alt={"Visa card"}
                        src={require("../../../assets/cards/" +
                          card.toString() +
                          ".png")}
                      />
                    );
                  })}
                  <div className={css(styles.secureLabel)}>
                    Payments taken securely through{" "}
                    <a
                      style={{ textDecoration: "underline" }}
                      target={"_blank"}
                      href={"https://stripe.com/gb"}
                    >
                      Stripe
                    </a>
                  </div>
                </div>
              </div>

              <ul className={css(styles.confirmList)}>
                <li className={css(styles.confirmListItem)}>
                  <Check className={css(styles.confirmCheck)} /> Please ensure
                  you're packed and ready before the van arrives
                </li>
                <li className={css(styles.confirmListItem)}>
                  <Check className={css(styles.confirmCheck)} /> A confirmation
                  email will be sent to {props.userDetails.email}
                </li>
                <li className={css(styles.confirmListItem)}>
                  <Check className={css(styles.confirmCheck)} /> Cash to pay
                  driver is {Utils.getPoundsAndPennies(finalReward)}
                </li>
              </ul>

              {/* <p className={css(styles.smallConfirmationText)}>
              If the job runs over {props.bookingDistance.minimumTimeHours + props.bookingGeneral.extraTime} hours, the
              driver may charge for overtime ({Utils.getPoundsAndPennies(props.bookingPrice.extraChargeValue)} for
              every {props.bookingPrice.extraChargeMins} minutes) - so do your best to prepare for easy loading.

              You may also need to pay the driver an
              additional {Utils.getPoundsAndPennies(1150)} for congestion charges if you are travelling through central
              London congestion zones

            </p> */}

              <div className={css(styles.namesContainer)}>
                <input
                  className={css(styles.nameInput)}
                  placeholder={"First name"}
                  value={props.firstName}
                  onChange={props.onChangeFirstName}
                />
                <input
                  className={css(styles.nameInput)}
                  placeholder={"Last name"}
                  value={props.lastName}
                  onChange={props.onChangeLastName}
                />
                <input
                  className={css(styles.nameInput)}
                  placeholder={"Email address"}
                  value={props.email}
                  onChange={props.onChangeEmail}
                />
                <input
                  className={css(styles.nameInput)}
                  placeholder={"Mobile number"}
                  value={props.mobileNumber}
                  onChange={props.onChangeMobileNumber}
                />
              </div>

              <div className={css(styles.priceBreakdownContainer)}>
                <div
                  className={css(STYLES.fullRow, styles.cardRow)}
                  style={{ paddingTop: 32 }}
                >
                  <div style={{ flex: 1 }} />
                </div>

                <h1
                  className={css(
                    styles.paymentMethodTitle,
                    STYLES.noSelect,
                    styles.choosePaymentMethodTitle
                  )}
                >
                  Choose Payment Method:
                </h1>
                <div className={css(styles.paymentBtnContainer)}>
                  <PrimaryButton
                    title={"Pay with card"}
                    className={css(
                      styles.cardBtn,
                      styles.payCard,
                      props.activePaymentMethod === PAYMENT_METHOD.CARD &&
                        styles.activeBtnStyle
                    )}
                    onClick={() => {
                      props.handleActivePaymentMethod(PAYMENT_METHOD.CARD);
                    }}
                  />
                  {/* <PrimaryButton
                    title={"Bank Transfer"}
                    className={css(
                      styles.cardBtn,
                      styles.bankTransfer,
                      props.activePaymentMethod === PAYMENT_METHOD.BANK &&
                        styles.activeBtnStyle
                    )}
                    onClick={() => {
                      props.handleActivePaymentMethod(PAYMENT_METHOD.BANK);
                    }}
                  /> */}

                  <PrimaryButton
                    title={"Paypal"}
                    className={css(
                      styles.cardBtn,
                      styles.bankTransfer,
                      props.activePaymentMethod === PAYMENT_METHOD.PAYPAL &&
                        styles.activeBtnStyle
                    )}
                    onClick={() => {
                      props.handleActivePaymentMethod(PAYMENT_METHOD.PAYPAL);
                    }}
                  />
                </div>

                {(props.activePaymentMethod === PAYMENT_METHOD.CARD ||
                  props.activePaymentMethod === PAYMENT_METHOD.PAYPAL) && (
                  <div>
                    <p className={css(styles.bookingText)}>
                      Booking will be confirmed immediately
                    </p>
                    <div className={css(STYLES.fullRow)}>
                      <div style={{ fontWeight: "bold", fontSize: 18 }}>
                        Deposit
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          paddingRight: 8,
                        }}
                      >
                        {Utils.getPoundsAndPennies(finalDeposit)}
                      </div>
                    </div>
                  </div>
                )}

                {props.activePaymentMethod === PAYMENT_METHOD.PAYPAL && (
                  <div className={css(styles.paypalWrapper)}>
                    <PayPalButton
                      amount={Utils.getPoundsAndPenniesWithNoSign(finalDeposit)}
                      // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                      // currency="GBP"
                      onError={(e) => {
                        console.log({ onError: e });
                      }}
                      catchError={(e) => {
                        // alert("catchError");
                        console.log({ catchError: e });
                      }}
                      onSuccess={(details, data) => {
                        props.submitBookingOnPayPalMode({ details, data });
                      }}
                      options={{
                        clientId:
                          "AQRSsgoTyxu9SNyZ59wOijDMG12RZkLzZd7XkBoRORtK0-6XUT2b96jYOqtSf2LELyeSqtQdZM-9gU8Y",
                        currency: "GBP",
                        disableFunding: "card",
                      }}
                      onButtonReady={() => {
                        // alert("On Button ready");
                      }}
                    />
                    {props.paypalSuccessLoading && (
                      <div
                        className={css(
                          styles.paypalBookingSuccessLoadingWrapper
                        )}
                      >
                        <CircularProgress
                          color={"inherit"}
                          size={16}
                          thickness={6}
                        />
                      </div>
                    )}
                  </div>
                )}
                {props.activePaymentMethod === PAYMENT_METHOD.BANK && (
                  <div>
                    <p className={css(styles.bookingText)}>
                      Transfer within 2 hours
                    </p>
                    <div className={css(STYLES.fullRow)}>
                      <div style={{ fontWeight: "bold", fontSize: 18 }}>
                        Deposit via bank transfer to JJD Ltd
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          paddingRight: 8,
                        }}
                      >
                        {Utils.getPoundsAndPennies(finalDeposit)}
                      </div>
                    </div>
                    <div className={css(styles.accountFieldContainer)}>
                      <div className={css(styles.accountDetails)}>
                        <p style={{ fontSize: 17, maxWidth: 67 }}>
                          Account Number
                        </p>
                        <h4 style={{ fontSize: 23, fontWeight: "normal" }}>
                          53371301
                        </h4>
                      </div>

                      <div className={css(styles.accountDetails)}>
                        <p style={{ fontSize: 17, maxWidth: 13 }}>Sort Code</p>
                        <h4 style={{ fontSize: 23, fontWeight: "normal" }}>
                          20-89-15
                        </h4>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {typeof window !== "undefined" &&
                !props.bookingPayment.paymentFailedError &&
                props.activePaymentMethod === PAYMENT_METHOD.CARD && (
                  <StripeProvider apiKey={props.stripeApiKey}>
                    <Elements>
                      <StripeAddCard
                        className={css(styles.stripeBtn)}
                        disabled={props.payButtonDisabled}
                      />
                    </Elements>
                  </StripeProvider>
                )}

              {props.activePaymentMethod === PAYMENT_METHOD.BANK && (
                <PrimaryButton
                  onClick={props.submitBookingOnBankMode}
                  disabled={props.payButtonDisabled}
                  title={"Confirm booking"}
                  className={css(styles.stripeBtn)}
                  large={true}
                  style={{ width: "100%", marginTop: 16 }}
                  // loading={props.loading}
                />
              )}

              {/* props.activePaymentMethod === PAYMENT_METHOD.PAYPAL && (
                <PrimaryButton
                  onClick={props.submitBookingOnPayPalMode}
                  disabled={
                    props.payButtonDisabled ||
                    _.isNull(props.paypalPaymentDetails)
                  }
                  title={"Confirm bookings"}
                  className={css(styles.stripeBtn)}
                  large={true}
                  style={{ width: "100%", marginTop: 16 }}
                  // loading={props.loading}
                />
              ) */}

              {props.bookingPayment.paymentFailedError && (
                <div>
                  {props.bookingPayment.paymentFailedError && (
                    <div className={css(styles.paymentFailedContainer)}>
                      <span className={css(styles.errorText)}>
                        {props.bookingPayment.paymentFailedError}
                      </span>
                      <div style={{ flex: 1 }} />
                      <div>
                        <span
                          onClick={props.changeCard}
                          className={css(styles.changeCardActionText)}
                        >
                          use a different card?
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <GiveBernyACall />
        </div>
      </div>
      <ExcellentReviews />
    </div>
  );
};

export default Payment;
